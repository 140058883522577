import { format, distanceInWords, differenceInDays } from 'date-fns'
import React, { Fragment } from 'react'
import BlockContent from '../block-content'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'
import { StyledDividerTwo } from '../icons/dividers'
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

const AboutPreview  = ({ _rawExcerpt, _rawSubtitle, _rawBody, title, companyInfo, mainImage, secondaryImage, id }) => {
	const styles = {
		heading: {
			color: "rgb(51, 51, 51)"
		},
		copy: {
			color: "rgb(0,0,0)",
			paddingBottom: 50,
			fontSize: '1.2rem',
			fontWeight: 500,
			maxWidth: 800,
			margin: 'auto',
		},
		containerWrapper: {
			backgroundColor: "#ffffff"
		},
		containerContent: {
			textAlign: 'center',
			paddingTop: 55,
			paddingBottom: 0,
		},
		divider: {
			paddingTop: 10,
			paddingBottom: 10,
			maxWidth: 280,
			margin: 'auto',
		}
	}
	return (
		<div 
			id={id}
		>
			<Container className={'info-banner'} fluid style={styles.containerWrapper}>
				<Row>
					<Col 
						style={styles.containerContent} 
						xs={{size:12, offset: 0}}
					>
							<h1 style={styles.heading} className="title">
								{
									_rawSubtitle ?
									<BlockContent blocks={ _rawSubtitle} style={styles.heading} /> :
									<Fragment>
										We are {companyInfo.name}
									</Fragment>
								}
								</h1>
							<div className="separator-container" style={styles.divider}>
								<StyledDividerTwo colorTwo={"#32325d"} colorOne={"#525f7f"}/>
							</div>
							<BlockContent blocks={ _rawBody} style={styles.copy} fullRow/>
					</Col>
				</Row>
			</Container>
    </div>
  )
}

export default AboutPreview
import React, { Fragment } from 'react'
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import { StyledDividerTwo } from '../icons/dividers'
import BlockText from '../block-text'
import BlockContent from '../block-content/'

import  * as ReactIcons from 'react-icons/md'

const SummaryList = ({list, title, copy}) => {
	const styles = {
		heading: {
			color: "#ffffff"
		},
		copy: {
			color: "#ffffff"
		},
		containerWrapper: {
			backgroundColor: "#171941",
			borderTop: '2px solid #8c3db9',
			borderBottom: '2px solid #8c3db9',
			paddingTop: 40
		},
		containerContent: {
			textAlign: 'center',
			paddingTop: 30,
			paddingBottom: 10,
		},
		divider: {
			paddingTop: 10,
			paddingBottom: 10,
			maxWidth: 280,
			margin: 'auto',
		},
		listItemsWrapper: {
			paddingTop: 20,
			paddingRight: 40,
			paddingLeft: 40,
			paddingBottom: 60
		},
		listItem: {
			display: 'flex',
			flexFlow: 'column',
			alignItems: 'center',
			paddingBottom: 20
		},
		listItemHeading: {
			marginBottom: 10
		},
		listItemIconWrapper: {
			minHeight: 100,
			display: 'flex',
			flexFlow: 'column',
			justifyContent: 'space-evenly',
			alignItems: 'center',
		},
		lineItemText: {
			color: '#fff',
			paddingBottom: 30,
			fontSize: '1.2rem'
		}
	}
	return (
		<Row style={styles.containerWrapper}>
			<Col xs={12}>
				<Container>
					<Row>
						<Col 
							style={styles.containerContent} 
							xs={{size:10, offset: 1}}
						>
								<h1 style={styles.heading} className="title">{title}</h1>
								<div className="separator-container" style={styles.divider}>
									<StyledDividerTwo colorTwo={"#525f7f"} colorOne={"#ffffff"}/>
								</div>
								<div style={{display: 'flex', flexFlow: 'column nowrap'}}>
									<div style={{paddingLeft: 0, margin: 0, paddingTop: 10}}>
										<h3 className="description">
											<BlockContent blocks={copy} style={styles.lineItemText}/>
										</h3>
									</div>
							</div>
						</Col>
					</Row>
					<Row style={styles.listItemsWrapper}>
						{
							list && list.map((listItem, idx, {length}) => {
								var Component = ReactIcons[listItem.icon]
								return(
									<Fragment key={Math.random(10)}>
										<Col xs={12} sm={12/length} style={styles.listItem}>
											<div style={styles.listItemIconWrapper}>
												{
													Component && (
															<Component fontSize={35} color={'#8c3db9'}/>
													)
												}
												<h3 style={styles.listItemHeading}>{listItem.title}</h3>
											</div>
											<div>
												<BlockContent blocks={listItem.subtitle} style={{ ...styles.lineItemText, ...{fontSize: '1rem', textAlign: 'center'}}}/>
											</div>
										</Col>
									</Fragment>
								)
							})
						}
					</Row>
				</Container>
			</Col>
		</Row>
	)
}

export default SummaryList;

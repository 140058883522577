import React from "react";

export const StyledDividerOne = ({color, ...etc}) => (
	<svg width="100%" viewBox="0 0 210 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...etc}>
		<path fill-rule="evenodd" clip-rule="evenodd"
			d="M108 0H102V22H108V0ZM0 6.5H97V14.5H48V10H34V14.5H0V6.5ZM162 14.5H113V6.5H210V14.5H176V10H162V14.5Z"
			fill={color ? color : "#000"} />
	</svg>

);


export const StyledDividerTwo = ({colorOne, colorTwo, ...etc}) => (
	<svg viewBox="0 0 166 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...etc}>
		<path d="M78.8458 14L76.4455 11.6256L80.9867 7.83255L83.8627 10.7085C83.693 10.7314 83.5198 10.7433 83.3438 10.7433C82.7217 10.7433 82.1342 10.5954 81.6144 10.3328L81.6138 10.3324L81.6138 10.3325L78.8458 14Z" fill={colorOne ? colorTwo : "#D0D0D0"}/>
		<path d="M87.1481 6.37241C87.1721 6.54575 87.1844 6.72278 87.1844 6.9027C87.1844 7.5437 87.0274 8.14801 86.7497 8.67928L86.6654 8.8057L90.3676 11.3834L88.1273 13.8227L84.2434 9.2728L87.1481 6.37241Z" fill={colorOne ? colorTwo : "#D0D0D0"}/>
		<path d="M78.915 0.173431L76 2.54353L80.0006 5.02175L79.9688 5.06868C79.6719 5.61376 79.5032 6.23874 79.5032 6.90312C79.5032 7.07257 79.5142 7.23946 79.5355 7.40312L82.401 4.55897L78.915 0.173431Z" fill={colorOne ? colorTwo : "#D0D0D0"}/>
		<path d="M82.7989 3.10102L85.688 6.00309L90.6704 2.48255L87.5867 0L85.2295 3.58109L85.0427 3.45732C84.5305 3.20428 83.9538 3.0621 83.3438 3.0621C83.1589 3.0621 82.9771 3.07516 82.7991 3.10043L82.7989 3.10102Z" fill={colorOne ? colorTwo : "#D0D0D0"}/>
		<path d="M87.1345 12.6597L85.1254 10.306C84.5929 10.5853 83.9868 10.7433 83.3438 10.7433C82.7215 10.7433 82.1337 10.5953 81.6138 10.3325L79.7528 12.7982C80.7958 13.4371 82.0225 13.8054 83.3352 13.8054C84.7401 13.8054 86.0464 13.3836 87.1345 12.6597Z" fill={colorOne ? colorOne : "#000"}/>
		<path d="M86.7969 5.21953C87.0451 5.72779 87.1844 6.29897 87.1844 6.9027C87.1844 7.59821 86.9995 8.25054 86.6762 8.81323L89.1754 10.5533C89.827 9.50309 90.2033 8.26413 90.2033 6.9373C90.2033 5.67419 89.8623 4.49072 89.2674 3.47391L86.7969 5.21953Z" fill={colorOne ? colorOne : "#000"}/>
		<path d="M85.2415 3.56289C84.6817 3.24415 84.034 3.06208 83.3438 3.06208C82.6999 3.06208 82.093 3.22053 81.56 3.50058L79.6727 1.12616C80.7327 0.456647 81.9887 0.0691833 83.3352 0.0691833C84.6361 0.0691833 85.8525 0.430883 86.8894 1.0592L85.2415 3.56289Z" fill={colorOne ? colorOne : "#000"}/>
		<path d="M77.4297 3.42871C76.8183 4.45557 76.4671 5.65542 76.4671 6.93728C76.4671 8.31835 76.8747 9.60421 77.5763 10.6811L79.9487 8.69958C79.6643 8.16342 79.5032 7.55186 79.5032 6.90268C79.5032 6.21806 79.6824 5.57528 79.9963 5.01865L77.4297 3.42871Z" fill={colorOne ? colorOne : "#000"}/>
		<rect y="6" width="71" height="2" fill={colorOne ? colorOne : "#000"}/>
		<rect x="95" y="6" width="71" height="2" fill={colorOne ? colorOne : "#000"}/>
	</svg>
);

import { Link } from 'gatsby'
import React from 'react'
import ProjectPreview from '../project-preview/project-preview'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'
import MdLaptop from 'react-ionicons/lib/MdLaptop'
import BlockText from '../block-text'
import { StyledDividerTwo } from '../icons/dividers'

// reactstrap components
import {
  Button,
  Container,
  Row,
	Col,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Badge,
} from "reactstrap";

const ProjectPreviewGrid = ({title, supertitle, nodes, browseMoreHref, isSinglePageApp, rawExcerpt}) => {
	const styles = {
		heading: {
			color: "rgb(51, 51, 51)"
		},
		copy: {
			color: "rgb(0, 0, 0)"
		},
		containerWrapper: {
			backgroundColor: "#ffffff"
		},
		containerContent: {
			textAlign: 'center',
			paddingTop: 55,
			paddingBottom: 35,
		},
		divider: {
			paddingTop: 10,
			paddingBottom: 10,
			maxWidth: 280,
			margin: 'auto',
		}
	}
  return (
		<Col lg={12} style={styles.containerWrapper}>
			<Row>
					<Col 
						style={styles.containerContent} 
						xs={{size:10, offset: 1}}
					>
							{/* <h6 style={styles.heading} className={'supertitle'}>{supertitle}</h6> */}
							<h1 style={styles.heading} className="title">{title}</h1>
							<div className="separator-container" style={styles.divider}>
								<StyledDividerTwo colorTwo={"#32325d"} colorOne={"#525f7f"}/>
							</div>
							<div style={{display: 'flex', flexFlow: 'column nowrap'}}>
								<div style={{paddingLeft: 0, margin: 0, paddingTop: 10}}>
									<h3 className="description">
										<BlockText blocks={rawExcerpt} styles={styles.copy}/>
									</h3>
								</div>
						</div>
					</Col>
				</Row>
			{/* <Row>
				{title && (
					<div className={'ml-auto mr-auto text-center'}>
						<h6 className={'supertitle'}>{supertitle}</h6>
						<h2 className={'title'}>{title}</h2>
					</div>
				)}
			</Row> */}
			<Row className={'no-gutters'}>
				{nodes &&
					nodes.map((node, index, array) => (
						<Col lg={Math.floor( 12 / array.length )} key={node.id}>
							<ProjectPreview {...node} />
						</Col>
					))}
			</Row>
			{
				isSinglePageApp ? 
				null :
				<Row>
					<Col md={"12"} style={{display: "flex", justifyContent: "flex-end"}}>
						<Card className="card-cta no-margin no-radius">
							<CardBody>
								<div className="content-center">
								<h2>
								Want to Read About More Projects?
								</h2>
								{browseMoreHref && (
									<Link to={browseMoreHref}>
										<Button
										className="btn btn-success btn-round"
										color="success"
										type="button"
										>
											Click Here <IosArrowForward/>
										</Button>
									</Link>
								)}
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			}
		</Col>
  )
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewGrid

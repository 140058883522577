import { Link } from 'gatsby'
import React, { useState, Fragment} from 'react'
import { cn, buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockText from '../block-text'
import BlockContent from '../block-content'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'
import IosClose from 'react-ionicons/lib/IosClose'

// reactstrap components
import {
  Button,
	Card,
	Badge,
	Fade,
  CardImg,
  CardHeader,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
	Col,
	Modal, ModalHeader, ModalBody, ModalFooter 
} from "reactstrap";

if (!Array.prototype.getRandomIndex) {
  Array.prototype.getRandomIndex = function() {
    return this[Math.floor(Math.random() * this.length)];
  }
}


const PreviewCardTitleCTA = (props) => (
	<Card 
		className={`card-blog card-plain`}>
		<CardHeader style={{paddingLeft: 0}}>
			<h2 className="title">{props.title}</h2>
			{ 
				props._rawCategories && ( props._rawCategories.map( category => (
					<Badge key={Math.random(10)} color={tagName.getRandomIndex()} className="mr-1">
						{category.title}
					</Badge>
				)) )
				} 
		</CardHeader>
		<div className="card-image">
			<img
				alt="..."
				className="img rounded img-raised"
				src={`${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).url()}`}
			/>
		</div>
		<CardFooter style={{
				display: 'flex',
				flexFlow: 'row',
				justifyContent: 'flex-end',
		}}>
			<div className="author">
				<Button
					className="btn-round btn-simple"
					color="primary"
				>
					<IosArrowForward/>
					Read More
				</Button>
			</div>
		</CardFooter>
	</Card>
);

const PreviewBackgroundImage = (props) => (
	<Card className={`card card-background partial ${props.isTitle ? "title-card" : ""}`} 
			style={{ 
				backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).url()})`,
				height:'100%'
			}}>
			<CardBody className={"content-bottom"}>
					<CardHeader>
						{
							props.icon && (
								<props.icon fontSize={"45px"}/>
							)
						}
						{ 
							props._rawCategories && ( props._rawCategories.map( category => (
								<div 
									key={Math.random(10)}
									className={'card-category'}
									style={{
										textTransform: 'uppercase',
										fontWeight: '400',
										fontSize: '.75rem',
										lineHeight: '1.2rem',
										marginBottom: '.1rem',
										marginTop: '.1rem',
										color: "#ffffff"
									}}>
									{category.title}
								</div>
							)) )
						} 
					</CardHeader>
					<CardText>
						<span className={"card-title"}>{props.title}</span>
					</CardText>
			</CardBody>
		</Card>
);


const ProjectPreview = ({_rawCategories, mainImage, title, _rawExcerpt, _rawSubtitle, _rawBody, ...etc}) => {
	const [fadeIn, setFadeIn] = useState(false)
	const [modal, setModal] = useState(false);
	const toggleFade = (bool) => typeof bool === "boolean" ? setFadeIn(bool) : setFadeIn(!fadeIn)
	const toggleModal = (bool) => typeof bool === "boolean" ? setModal(bool): setModal(!modal)
	
	const styles = {
		cardBackground: {
			backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(mainImage.asset) ? mainImage.asset : imageUrlFor(buildImageObj(mainImage)).url()})`,
			height:'calc(100% - 20px)',
			position: 'absolute',
			width: 'calc(100% - 20px)',
			backgroundSize: 'cover',
			backgroundPosition: 'center center',
		},
		card: {
			position: 'relative',
			zIndex: 1,
			padding: 10,
			background: '#fff',
			boxShadow: 'none',
			height: '100%'
		},
		cardBody: {
			position: 'relative',
			zIndex: 4,
		},
		cardTitle: {
			marginTop: 'auto'
		},
		overlay: {
			position: 'absolute',
			top: 10,
			left: 10,
			height: 'calc(100% - 20px)',
			zIndex: 2,
			width: 'calc(100% - 20px)',
			display: 'block',
			background: `rgba(50, 50, 93, 0.6)`,
		},
		contentWrapper: {
			minHeight: 500,
			display: 'flex',
			flexFlow: 'column',
		},
		excerptPreview: {
			flex: '1 1 100%',
			display: 'flex',
			flexFlow: 'column',
			alignItems: 'center',
			minHeight: 420,
			overflow: 'hidden'
			// justifyContent: 'space-evenly',
		},
		subtitlePreview: {
			fontWeight: 'bold',
			textTransform: 'uppercase',
			marginTop: 40,
			marginBottom: 10,
			color: '#fff'
		},
		cardText: {
			marginTop: 'auto',
			marginBottom: 30,
			paddingRight: 20,
			paddingLeft: 20,
			fontSize: 16,
			fontWeight: 600,
			color: '#fff'
		},
		badgeWrapper: {
			display: 'flex'
		},
		badge: {
			flex: '0 0 auto'
		},
		button: {
			padding: '6px 14px',
			fontSize: 12,
			marginBottom: 30
		},
		modalButton: {
			padding: '12px 24px',
			fontSize: 16,
			marginBottom: 15
		},
		modalCloseButton: {
			padding: '0px 6px',
			fontSize: 40,
			margin: '-0.2rem'
		},
		modalFooterCloseButton: { 
			display: 'flex', 
			justifyContent: 'center',
			alignItems: 'center'
		}
	}
	return(
		<Fragment>
			<Card
				className="card-blog no-border-radius no-margin"
				style={styles.card}
				onMouseEnter={() => toggleFade(true)}
				onMouseLeave={() => toggleFade(false)}
				onTouchStart={() => toggleFade(true)}
				onTouchEnd={() => toggleFade(false)}
			>
				<div
					style={styles.cardBackground}
				/>
				<Fade in={fadeIn}>
					<div style={{ ...styles.overlay, ...{zIndex: 4, background: `rgba(50, 50, 93, 0.8)`}}}/>
				</Fade>
				<div style={styles.overlay}/>
				<CardBody style={styles.cardBody}>
					<div style={styles.contentWrapper}>
						<Fade style={styles.excerptPreview} in={fadeIn} 
							onClick={toggleModal}
						>
							<BlockContent blocks={_rawSubtitle} style={styles.subtitlePreview}/>
							<CardText tag={'div'} style={styles.cardText}>
								<BlockText blocks={_rawExcerpt} style={{paddingBottom: 15}}/>
							</CardText>
						</Fade>
						<div>
							{ 
								_rawCategories && ( _rawCategories.map( (category, idx) => (
									<Badge key={Math.random(10)} color={"success"} className="mr-1" style={styles.badge}>
										{category.title}
									</Badge>
								)) )
							} 
						</div>
						<CardTitle tag="h3" style={styles.cardTitle}>{title}</CardTitle>
					</div>
				</CardBody>
			</Card>
			<Modal 
				isOpen={modal} 
				toggle={toggleModal} 
				onClosed={toggleFade} 
				scrollable 
				modalClassName={'modal-full'}
				>
				<ModalHeader 
					toggle={toggleModal} 
					close={
						<Button 
							className="btn btn-simple btn-round"
							color="neutral"
							type="button"
							style={styles.modalCloseButton}
							onClick={toggleModal}
						>
							<IosClose fontSize={`${styles.modalCloseButton.fontSize}px`}/>
						</Button>
					}
					/>
					<ModalBody>
						<Container>
							<Row>
								<Col>
									<CardTitle tag="h3" style={styles.cardTitle}>{title}</CardTitle>
								</Col>
							</Row>
							<Row>
								<Col>
									<BlockContent blocks={_rawSubtitle} style={styles.subtitlePreview}/>
								</Col>
							</Row>
							<Row>
								<Col>
									<BlockContent blocks={_rawBody} pStyle={{fontSize: '1.2rem', lineHeight: '1.8rem'}} style={{color: '#fff'}}/>
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Container>
							<Row>
								<Col style={styles.modalFooterCloseButton}>
									<Button 
										className="btn btn-simple btn-round"
										color="neutral"
										type="button"
										style={styles.modalButton}
										onClick={toggleModal}
									>
										Close  <IosArrowForward fontSize={`${styles.button.fontSize}px`}/>
									</Button>
								</Col>
							</Row>
						</Container>
					</ModalFooter>
      </Modal>
		</Fragment>
	)
}

const ProjectPreviewTwo = (props) => {
  return (
		<Link style={{
					display: 'block',
					color: 'inherit',
					textDecoration: 'none',
					height: '100%'
				}}
				to={ props.slug && props.slug.current ? `/project/${props.slug.current}` : props.link}
			>
      {props.mainImage && props.mainImage.asset && (
				<>
					{
						props.isTitle ? 
						<PreviewBackgroundImage { ...props}/> :	
						<PreviewCardTitleCTA { ...props}/>
					}
				</>
      )}
    </Link>
  )
}

        
// function ProjectPreview (props) {
//   return (
//     <Link className={styles.root} to={`/project/${props.slug.current}`}>
//       <div className={styles.leadMediaThumb}>
//         {props.mainImage && props.mainImage.asset && (
//           <img
//             src={imageUrlFor(buildImageObj(props.mainImage))
//               .width(600)
//               .height(Math.floor((9 / 16) * 600))
//               .url()}
//             alt={props.mainImage.alt}
//           />
//         )}
//       </div>
//       <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
//       {props._rawExcerpt && (
//         <div className={styles.excerpt}>
//           <BlockText blocks={props._rawExcerpt} />
//         </div>
//       )}
//     </Link>
//   )
// }

export default ProjectPreview

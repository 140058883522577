import React, { Fragment } from 'react'
import BlockContent from '../block-content'
import { StyledDividerTwo } from '../icons/dividers'
import { imageUrlFor } from '../../lib/image-url'
import { buildImageObj } from '../../lib/helpers'


// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

const EventAnnouncement  = ({ _rawExcerpt, _rawSubtitle, _rawBody, title, companyInfo, mainImage, secondaryImage, id }) => {
	const styles = {
		heading: {
			color: "rgb(255, 255, 255)"
		},
		copy: {
			color: "rgb(255,255,255)",
			paddingBottom: 50,
			fontWeight: 500,
			maxWidth: 800,
			margin: 'auto',
		},
		containerWrapper: {
			backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(mainImage.asset) ? mainImage.asset : imageUrlFor(buildImageObj(mainImage)).url()})`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
		},
		containerContent: {
			textAlign: 'center',
			paddingTop: 30,
			paddingBottom: 15,
		},
		divider: {
			paddingTop: 10,
			paddingBottom: 10,
			maxWidth: 280,
			margin: 'auto',
		}
	}

	return (
		<div 
			id={id}
		>
			<Container className={'event-banner'} fluid style={styles.containerWrapper}>
				<Row>
					<Col 
						style={styles.containerContent} 
						xs={{size:12, offset: 0}}
					>
							<h1 style={styles.heading} className="title">
								{
									_rawSubtitle ?
									<BlockContent blocks={ _rawSubtitle} style={styles.heading} /> :
									<Fragment>
										Event Announcment
									</Fragment>
								}
								</h1>
							<div className="separator-container" style={styles.divider}>
								<StyledDividerTwo colorTwo={"#525f7f"} colorOne={"#ffffff"}/>
							</div>
							<BlockContent blocks={ _rawBody} style={styles.copy} fullRow/>
					</Col>
				</Row>
			</Container>
    </div>
  )
}

export default EventAnnouncement
import React, {Fragment, useState} from "react";
import { navigate  } from 'gatsby'
import BlockContent from '../../components/block-content'
import { buildImageObj, cn } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import MdHeart from 'react-ionicons/lib/MdHeart'
import IosPinOutline from 'react-ionicons/lib/IosPinOutline'
import IosPhonePortrait from 'react-ionicons/lib/IosPhonePortrait'
import MdContacts from 'react-ionicons/lib/MdContacts'
import IosMail from 'react-ionicons/lib/IosMail'
import { StyledDividerTwo } from '../icons/dividers'
import BlockText from '../block-text'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardSubtitle,
	Form,
  Label,
  Input,
  InputGroup,
  FormGroup,
  Container,
  Row,
	Col,
	Badge,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

class ContactUsForm extends React.Component {
  state={}
  onFocus = (event, field) => {
		this.setState({[`${field}Focus`]: true});
  }
  
  onBlur = (event, field) => {
		this.setState({[`${field}Focus`]: false});
	}


  render() {
  const { pageBranding, _rawBody, _rawSubtitle, title} = this.props.page;
  const { hideBrand, companyInfo} = this.props;

	const fields = [
      {
        props: {
          type: 'text',
          autoComplete: 'off',
          'aria-label': 'Your First Name ',
          name: 'firstname',
					required: true
        },
				classLabel: 'firstname',
				colWidth: 6
      },
      {
        props: {
          type: 'text',
          autoComplete: 'off',
          'aria-label': 'Your Last Name ',
          name: 'lastname',
					required: true
        },
				classLabel: 'lastname',
				colWidth: 6
      },
      {
        props: {
          type: 'email',
          autoComplete: 'email',
          tabIndex: '0',
          'aria-label': 'Your Email Address',
          name: '_replyto',
					required: true
        },
				classLabel: 'email',
				colWidth: 12
      },
      {
        props: {
          type: 'textarea',
          autoComplete: 'off',
          tabIndex: '0',
          'aria-label': 'Your Message',
					name: 'message',
					rows:"10",
          required: true
        },
				classLabel: 'message',
				colWidth: 12
      }
		]
		
		const styles = {
			input: {
				borderTop: 'none',
				borderLeft: 'none',
				borderRight: 'none',
				borderRadius: 0,
				padding: '8px 0',
				height: 'calc(2.85rem + 2px)',
				fontSize: '1.1rem',
				color: "#fff"
			},
			inputTextGroup: {
				height: 'calc(4.85rem + 2px)'
			},
			formContainer: {
				display: 'flex',
				justifyContent: 'center',
				flexFlow: 'column',
			},
			inputGroup: {
				marginBottom: 30,
			},
			heading: {
				color: "#fff"
			},
			copy: {
				color: "#fff"
			},
			containerContent: {
				textAlign: 'center'
			},
			divider: {
				paddingTop: 10,
				paddingBottom: 10,
				maxWidth: 280,
				margin: 'auto',
			}
		}
    return (
      <Fragment>
				<div
					style={{
						padding: '40px 20px 80px 20px',
						width: '100%',
						borderTop: '2px solid #8c3db9'
					}}
				>
				<Container>
				<Row>
						<Col 
							style={styles.containerContent} 
							xs={{size:10, offset: 1}}
						>
								{/* <h6 style={styles.heading} className={'supertitle'}>{supertitle}</h6> */}
								<h1 style={styles.heading} className="title">{title}</h1>
								<div className="separator-container" style={styles.divider}>
									<StyledDividerTwo colorTwo={"#525f7f"} colorOne={"#ffffff"}/>
								</div>
									{
										_rawSubtitle && (
											<div style={{display: 'flex', flexFlow: 'column nowrap'}}>
												<div style={{paddingLeft: 0, margin: 0, paddingTop: 10}}>
													<h3 className="description">
														<BlockText blocks={_rawSubtitle} style={styles.copy}/>
													</h3>
												</div>
											</div>
										)
									}
						</Col>
					</Row>
					<Row>
						<Col xs={12} md={4} style={{padding: "10px 20px"}}>
							<Row>
								<Col sm={"12"}>
									<BlockContent blocks={_rawBody || []} />
								</Col>
							</Row>
							<Row>
								<Col sm={"12"}>
									<div className="info info-horizontal">
										<div className="icon icon-primary">
											<IosPinOutline fontSize={'36px'} />
										</div>
										<div className="description">
											<div className="description" style={{padding: '0 25px'}}>
												<h4 className="info-title" style={{margin: 0}}>
													Made in {companyInfo.city && (<span>{companyInfo.city}</span>)} with&nbsp;&nbsp;<span className={"icon icon-danger"}><MdHeart beat={true}/></span>
												</h4>
												{companyInfo.address1 && (<span>{companyInfo.address1}<br/></span>)}
												{companyInfo.address1 && (<br/>)}
												{companyInfo.address2 && (<span>{companyInfo.address2}<br/></span>)}
												{companyInfo.address2 && (<br/>)}
												{companyInfo.city && (<span>{companyInfo.city}&nbsp;</span>)}
												{companyInfo.state && (<span>{companyInfo.state},&nbsp;</span>)}
												{companyInfo.zipCode && (<span>{companyInfo.zipCode}</span>)}
												{companyInfo.city && (<br/>)}
												{companyInfo.country && (<span>{companyInfo.country}</span>)}
											</div>
										</div>
									</div>
									<div className="info info-horizontal">
										<div className="icon icon-info">
											<IosPhonePortrait fontSize={'36px'} />
										</div>
										<div className="description">
											<div className="description" style={{padding: '0 25px'}}>
												<h4 className="info-title" style={{margin: 0}}>Reach Us Here</h4>
												{companyInfo.email && (<span>{companyInfo.email}<br/></span>)}
												{companyInfo.phoneNumber && (<span>{companyInfo.phoneNumber}<br/></span>)}
												{companyInfo.miscInfo && (<span>{companyInfo.miscInfo}</span>)}
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
						<Col xs={{size: 12, offset: 0}} md={{size: 7, offset: 1}} style={styles.formContainer}>
							<Form 
								role="form"
								className="form"
								action="https://formspree.io/xoyggjgx"
								method = "POST"
								>
									<Row>
										<Col md={fields[0].colWidth} style={styles.inputGroup}>
											<FormGroup>
												<label>{fields[0].props['aria-label']}</label>
												<InputGroup
													className={`${this.state[`${fields[0].classLabel}Focus`] ? 'input-group-focus' : ''}`}
												>
													<Input
														style={styles.input}
														placeholder={fields[0].props['aria-label']}
														type={fields[0].props.type}
														onFocus={e => (this.onFocus(e, fields[0].classLabel))}
														onBlur={e => (this.onBlur(e, fields[0].classLabel)) }
														{ ...fields[0].props}
													/>
												</InputGroup>
											</FormGroup>
										</Col>
										<Col md={fields[1].colWidth} style={styles.inputGroup}>
											<FormGroup>
												<label>{fields[1].props['aria-label']}</label>
												<InputGroup
													className={`${this.state[`${fields[1].classLabel}Focus`] ? 'input-group-focus' : ''}`}
												>
													<Input
														style={styles.input}
														placeholder={fields[1].props['aria-label']}
														type={fields[1].props.type}
														onFocus={e => (this.onFocus(e, fields[1].classLabel))}
														onBlur={e => (this.onBlur(e, fields[1].classLabel)) }
														{ ...fields[1].props}
													/>
												</InputGroup>
											</FormGroup>
										</Col>
										<Col md={fields[2].colWidth} style={styles.inputGroup}>
											<FormGroup>
												<label>{fields[2].props['aria-label']}</label>
												<InputGroup
													className={`${this.state[`${fields[2].classLabel}Focus`] ? 'input-group-focus' : ''}`}
												>
													<Input
														style={styles.input}
														placeholder={fields[2].props['aria-label']}
														type={fields[2].props.type}
														onFocus={e => (this.onFocus(e, fields[2].classLabel))}
														onBlur={e => (this.onBlur(e, fields[2].classLabel)) }
														{ ...fields[2].props}
													/>
												</InputGroup>
											</FormGroup>
										</Col>
										<Col md={fields[3].colWidth} style={styles.inputGroup}>
											<FormGroup>
												<label>{fields[3].props['aria-label']}</label>
												<InputGroup
													className={`${this.state[`${fields[3].classLabel}Focus`] ? 'input-group-focus' : ''}`}
												>
													<Input
														style={{ ...styles.input, ...styles.inputTextGroup}}
														placeholder={fields[3].props['aria-label']}
														type={fields[3].props.type}
														onFocus={e => (this.onFocus(e, fields[3].classLabel))}
														onBlur={e => (this.onBlur(e, fields[3].classLabel)) }
														{ ...fields[3].props}
													/>
												</InputGroup>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col sm={4} className={'ml-auto mr-auto text-center'}>
											<Button type="submit" className={'btn btn-success btn-round btn-simple btn btn-success'} size="lg">
													Submit
											</Button>
										</Col>
									</Row>
							</Form>
						</Col>
					</Row>
				</Container>
				</div>
      </Fragment>
    );
  }
}

export default ContactUsForm;
